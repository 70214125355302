import React, { useState, useCallback, useRef } from "react";
import LoginStyle from "./LoginStyle";
import { useDispatch, useSelector } from "react-redux";
import { main } from "../../store/slices";
import api from "../../api";
import { get } from "lodash";
import { json, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { ErrorModal } from "../../components/Modal";
import { useTranslation } from "react-i18next";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

const Login = () => {
	const { t } = useTranslation();
	const { setMe, setToken, setInfo } = main.actions;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { info } = useSelector((state) => state.main);
	const loginValue = get(info, "login", "");
	const passwordValue = get(info, "password", "");

	const [login, setLogin] = useState(loginValue);
	const [password, setPassword] = useState(passwordValue);
	const [isLoading, setIsLoading] = useState(false);
	const [isShow, setIsShow] = useState(false);

	const errorRef = useRef();

	const loginProfile = () => {
		setIsLoading(true);
		const prod = {
			CompanyDB: `AE08_PROD011`,
			UserName: `sb1\\aeg_user001a`,
			Password: `pUye+-J8`,
			Language: 24,
		};

		const prod1 = {
			CompanyDB: `AE08_TEST011`,
			UserName: `sb1\\aeg_user001a`,
			Password: `Z1*!juj1`,
			Language: 24,
		};

		api
			.post("/Login", prod)
			.then((res) => {
				dispatch(setToken(get(JSON.parse(res.data), "SessionId", "")));
				secure();
			})
			.catch((err) => {
				setIsLoading(false);
				errorRef.current?.open(
					get(JSON.parse(err.response.data), "error.message", "Login yoki parol xato")
				);
				console.log();
			});
	};

	const secure = () => {
		api
			.get(
				`EmployeesInfo?$select=EmployeeID,LastName,FirstName,U_CashAccount,U_CardAccount,U_TransAccount,U_BonusAccount,SalesPersonCode,U_CashUzsAccount,U_Sklad&$filter=EmployeeCode eq '${login}' and ExternalEmployeeNumber eq '${password}'`
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), "value[0]", {});
				if (get(resData, "EmployeeID", "")) {
					getCustomerGroups(resData);
					dispatch(setInfo({ login, password }));
				} else {
					errorRef.current?.open();
				}
				setIsLoading(false);
			})
			.catch((err) => {
				console.log("err secure ", err);
				setIsLoading(false);
			});
	};

	const getCustomerGroups = (data) => {
		api
			.get(`SQLQueries('getBPGroups')/List`)
			.then((res) => {
				const resData = get(JSON.parse(res.data), "value", []);
				const obj = {};

				resData.forEach((element, index) => {
					obj[`${element.GroupCode}`] = element.GroupName;
				});

				data[`GroupCodes`] = obj;
				dispatch(setMe(data));
				navigate("/allProducts");
				setIsLoading(false);
			})
			.catch((err) => {
				console.log("err getCustomerGroups:", err);
				setIsLoading(false);
			});
	};

	return (
		<>
			<LoginStyle>
				<div className="mainContainer">
					<div className="container">
						<div style={{ width: "50%" }}>
							<p className="logoTitle">SAP</p>
						</div>
						<div style={{ width: "50%" }}>
							<div className="card">
								<div className="labelCard">
									<label htmlFor="login" className="label">
										{t("Login")}
									</label>
								</div>
								<div className="passwordCard">
									<input
										type="text"
										id="login"
										placeholder={t("Login")}
										defaultValue={loginValue}
										className="input2"
										onChange={(e) => setLogin(e.target.value)}
									/>
								</div>
							</div>
							<div className="card">
								<div className="labelCard">
									<label htmlFor="password" className="label">
										{t("Password")}
									</label>
								</div>
								<div className="passwordCard">
									<input
										type={isShow ? "text" : "password"}
										id="password"
										placeholder={t("Password")}
										defaultValue={passwordValue}
										className="input2"
										onChange={(e) => setPassword(e.target.value)}
									/>
									<button className="eyeBtn" onClick={() => setIsShow((pr) => !pr)}>
										{isShow ? <AiFillEye /> : <AiFillEyeInvisible />}
									</button>
								</div>
								<div className="btnCardBottom">
									<Button
										onClick={() => loginProfile()}
										children={t("Вход")}
										isLoading={isLoading}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</LoginStyle>
			<ErrorModal
				getRef={(ref) => {
					errorRef.current = ref;
				}}
				title={t("Ошибка логина или пароля, проверьте еще раз")}
			/>
		</>
	);
};

export default Login;
